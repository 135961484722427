(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/components/youtube-video/assets/javascripts/init.js') >= 0) return;  svs.modules.push('/components/components/youtube-video/assets/javascripts/init.js');

'use strict';

const logger = svs.core.log.getLogger('component:youtube-video_init');
const {
  trackEvent
} = svs.components.analytics;
const YOUTUBE_URL = 'https://www.youtube-nocookie.com';
const iframes = document.querySelectorAll('.js-youtube-video-iframe');
const eventNotifier = new EventTarget();
const videoStates = new Map();
svs.components.youtube_video.init = function (options) {
  if (!options.videoId) {
    logger.warn('Missing videoId');
    return;
  }
  if (options && options.selector && options.src) {
    const videoHtml = svs.components.youtube_video.templates.youtube_video(options);
    $(options.selector).append(videoHtml);
  } else {
    logger.warn('Error in options object', options);
  }
};
function sendTrackEvent(action, category, videoId, videoTitle, optValue) {
  trackEvent({
    action,
    category,
    opt_label: "".concat(videoId, " - ").concat(videoTitle),
    opt_value: optValue
  });
}

function handleVideoStart(videoId, videoTitle) {
  if (!videoStates.has(videoId)) {
    sendTrackEvent('Video start-youtube', 'Youtube video', videoId, videoTitle, 1);
    const timer = setTimeout(() => {
      sendTrackEvent('Video progress-youtube', 30, videoId, videoTitle, 1);
      videoStates.delete(videoId);
    }, 30000);
    videoStates.set(videoId, {
      timer,
      startTime: Date.now(),
      accumulatedTime: 0
    });
  } else {
    const videoState = videoStates.get(videoId);
    videoState.startTime = Date.now();
    if (!videoState.timer) {
      const remainingTime = 30000 - videoState.accumulatedTime * 1000;
      videoState.timer = setTimeout(() => {
        sendTrackEvent('Video progress-youtube', 30, videoId, videoTitle, 1);
        videoStates.delete(videoId);
      }, remainingTime);
    }
  }
}

function handleVideoPauseOrEnd(videoId, videoTitle) {
  const videoState = videoStates.get(videoId);
  if (videoState) {
    const playTime = (Date.now() - videoState.startTime) / 1000; 
    videoState.accumulatedTime += playTime;
    if (videoState.accumulatedTime >= 30) {
      sendTrackEvent('Video progress-youtube', 30, videoId, videoTitle, 1);
      videoStates.delete(videoId);
    } else {
      clearTimeout(videoState.timer);
      videoState.timer = null;
    }
  }
}

function sendMessage(videoId, command, optValue) {
  const iframe = document.querySelector(".js-youtube-video-".concat(videoId));
  if (iframe) {
    const message = {
      event: 'command',
      func: command,
      args: optValue ? [optValue] : []
    };
    iframe.contentWindow.postMessage(JSON.stringify(message), YOUTUBE_URL);
  }
}

svs.components.youtube_video.videoPlayer = {
  play: videoId => sendMessage(videoId, 'playVideo'),
  pause: videoId => sendMessage(videoId, 'pauseVideo'),
  stop: videoId => sendMessage(videoId, 'stopVideo'),
  seek: (videoId, position) => sendMessage(videoId, 'seekTo', position),
  volume: (videoId, volume) => sendMessage(videoId, 'setVolume', volume * 100),
  isPlaying: (videoId, callback) => eventNotifier.addEventListener("".concat(videoId, "_playing"), callback),
  isPaused: (videoId, callback) => eventNotifier.addEventListener("".concat(videoId, "_paused"), callback),
  onProgress: (videoId, callback) => eventNotifier.addEventListener("".concat(videoId, "_progress"), callback),
  getDuration: (videoId, callback) => eventNotifier.addEventListener("".concat(videoId, "_duration"), callback)
};

iframes.forEach(iframe => {
  iframe.contentWindow.postMessage('{"event":"listening","channel":"widget"}', '*');
});
let latestVideoId = null;
let latestVideoTitle = null;
function receiveMessage(event) {
  if (event.origin === YOUTUBE_URL) {
    var _data$info$videoData, _data$info$videoData2, _data$info$videoData3;
    let data;
    try {
      data = JSON.parse(event.data);
    } catch (e) {
      logger.warn('Failed to parse message data:', e);
      return;
    }
    if (!data || !data.info) {
      return;
    }

    const videoId = ((_data$info$videoData = data.info.videoData) === null || _data$info$videoData === void 0 ? void 0 : _data$info$videoData.video_id) || latestVideoId;
    const videoTitle = ((_data$info$videoData2 = data.info.videoData) === null || _data$info$videoData2 === void 0 ? void 0 : _data$info$videoData2.title) || latestVideoTitle;

    if ((_data$info$videoData3 = data.info.videoData) !== null && _data$info$videoData3 !== void 0 && _data$info$videoData3.video_id) {
      latestVideoId = videoId;
      latestVideoTitle = videoTitle;
    }
    if (!videoId) {
      return;
    }

    if (typeof data.info.playerState === 'number') {
      switch (data.info.playerState) {
        case 1:
          handleVideoStart(videoId, videoTitle);
          eventNotifier.dispatchEvent(new CustomEvent("".concat(videoId, "_playing"), {
            detail: {
              videoId,
              videoTitle
            }
          }));
          break;
        case 2: 
        case 0:
          handleVideoPauseOrEnd(videoId, videoTitle);
          eventNotifier.dispatchEvent(new CustomEvent("".concat(videoId, "_paused"), {
            detail: {
              videoId,
              videoTitle
            }
          }));
          break;
        default:
          logger.debug('Unhandled player state:', data.info.playerState);
      }
    }

    if (typeof data.info.currentTime === 'number') {
      eventNotifier.dispatchEvent(new CustomEvent("".concat(videoId, "_progress"), {
        detail: {
          videoId,
          videoTitle,
          currentTime: data.info.currentTime
        }
      }));
    }

    if (typeof data.info.duration === 'number') {
      eventNotifier.dispatchEvent(new CustomEvent("".concat(videoId, "_duration"), {
        detail: {
          videoId,
          videoTitle,
          duration: data.info.duration
        }
      }));
    }
  }
}

window.addEventListener('message', receiveMessage, false);

 })(window);